import { BellIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import LazyLoadImageProp from '../component/common/LazyLoadImage';
import { motion, AnimatePresence } from 'framer-motion';
import React, { lazy, memo, Suspense, useEffect, useContext, Fragment } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import allRoutes from '../routes/RouteFile';
import b2bRoutes from './adminRoutes';
import partnerRoutes from './partnerRoutes';
import expertRoutes from './expertRoutes';

import Loader from '../component/common/Loader';
import PropTypes from 'prop-types';
import Sidebar from '../layout/Sidebar';
import useWindowDimensions, { cleanLocalStorage, getLocalStorageItem } from '../utils/helper';
import { SidebarContext } from '../context/SidebarContext';
import RightSidebar from '../layout/RightSidebar';
import { Menu, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import PartnerSidebar from '../layout/PartnerSidebar';
import { Api } from '../api';
import ExpertSidebar from '../layout/expertSidebar';
// const NotFound = lazy(() => import('../container/404NotFound'));
const Login = lazy(() => import('../container/Login'));

const LoadSideBar = () => {
  const location = useLocation();
  return (
    <>
      {!['/login', '/reset-password', '/forgot-password']?.includes(location?.pathname) && (
        <Sidebar />
      )}
    </>
  );
};

const RoutesList = memo(() => {
  const { width } = useWindowDimensions();
  const { setShow } = useContext(SidebarContext);

  useEffect(() => {
    if (width < 1024) {
      setShow(false);
    }
  }, [width]);

  return (
    <Router>
      <ConditionalRoute />
    </Router>
  );
});

export const ConditionalRoute = () => {
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [notificationCounts, setNotificationCounts] = useState(0);

  const userData = getLocalStorageItem('userData') && JSON.parse(getLocalStorageItem('userData'));

  const name = userData?.name?.split(' ');
  const userType = userData?.userType;
  const [RoutesFile, setRoutesFile] = useState([]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const getUnreadNotificationCount = () => {
    Api.getUnreadNotificationCount()
      .then((res) => {
        setNotificationCounts(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      window.location.pathname !== '/login' &&
      (userData?.userType === 0 || userData?.userType === 1)
    )
      getUnreadNotificationCount();
  }, [window.location.pathname]);

  useEffect(() => {
    if (window.location.pathname !== '/login')
      if (userData?.userType === 0 || userData?.userType === 1) {
        setRoutesFile(allRoutes);
      } else if (userData?.userType === 3 || userData?.userType === 4) {
        setRoutesFile(b2bRoutes);
      } else if (userData?.userType === 5) {
        setRoutesFile(partnerRoutes);
      } else if (userData?.userType === 6) {
        setRoutesFile(expertRoutes);
      } else {
        setRoutesFile(allRoutes);
      }

    if (userData?.userType === 2) {
      cleanLocalStorage();
      window.location.href = '/login';
    }
  }, [window.location.pathname]);

  return (
    <>
      {!['/login', '/reset-password', '/forgot-password']?.includes(location?.pathname) ? (
        <>
          <div className="h-screen lg:p-4 grid grid-cols-6 overflow-hidden ">
            <motion.div
              initial={{
                x: -100,
                opacity: 0
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: {
                  duration: 0.5
                }
              }}
              className="lg:col-span-1 pointer-events-none h-[90%] lg:h-[95vh] lg:relative fixed z-50  "
            >
              <div
                onClick={() => {
                  setIsDrawerOpen(true);
                }}
                className="fixed pointer-events-auto w-10 h-10 p-1 dark:bg-shoorah-darkBgTabColor bg-white rounded-full shadow-md left-4 top-6"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className=" w-full h-full dark:text-white text-shoorah-sidebarBackground"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              </div>
              <div
                className={`h-full   ${
                  isDrawerOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0 '
                } transition-transform relative pointer-events-auto `}
              >
                <LoadSideBar />

                {isDrawerOpen && (
                  <div
                    onClick={() => {
                      setIsDrawerOpen(false);
                    }}
                    className="absolute w-8 h-8 p-1 bg-white shadow-md dark:bg-shoorah-darkBgTabColor dark:text-white rounded-full -right-10 top-6"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-full h-full"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                  </div>
                )}
              </div>
            </motion.div>
            <AnimatePresence exitBeforeEnter={true}>
              <motion.div
                key={location?.pathname}
                initial={{
                  y: 100,
                  opacity: 0
                }}
                animate={{
                  y: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.5
                  }
                }}
                className={`${
                  userType === 0 || userType === 1
                    ? 'lg:col-span-5 col-span-6'
                    : 'lg:col-span-4 col-span-6'
                } hide-scrollbar pb-8 lg:pt-0 pt-14 h-full lg:h-screen overflow-y-auto`}
              >
                <Suspense fallback={<Loader />}>
                  <Routes>
                    {RoutesFile.map((itm, key) =>
                      itm.private ? (
                        <Route
                          key={key}
                          exact={itm.exact}
                          path={itm.path}
                          name={itm.name}
                          Component={itm.component}
                        />
                      ) : (
                        <Route
                          key={key}
                          exact={itm.exact}
                          path={itm.path}
                          name={itm.name}
                          Component={itm.component}
                        />
                      )
                    )}
                    <Route exact path="/login" element={<Login />}>
                      {/* <Navigate to="/login" component={Login} /> */}
                    </Route>
                    <Route exact path="/" element={<Navigate to="/login" />}>
                      {/* <Navigate to="/login" component={Login} /> */}
                    </Route>
                    {/* <Route component={NotFound} /> */}
                  </Routes>
                </Suspense>

                {/* super admin custom header */}
                {(userType === 0 || userType === 1) && (
                  <div className="  fixed bg-shoorah-primary  py-4   flex justify-center items-center right-8 top-0 rounded-b-xl">
                    <div className="flex ">
                      <div className="self-center px-4 lg:px-7 ">
                        <div
                          onClick={() => {
                            userType === 3 || userType === 4
                              ? (window.location.href = `/${userData.slug}/notifications`)
                              : (window.location.href = '/notifications');
                          }}
                          className="w-8 aspect-square p-1 shadow-2xl flex justify-center  relative rounded-[50%] border border-[#EAEAEA] text-shoorah-customGray cursor-pointer self-center"
                        >
                          <BellIcon className="relative w-full dark:text-white text-white" />
                          {notificationCounts > 0 && (
                            <div className="absolute -right-[15px] shadow-2xl -top-[10px] rounded-[50%] text-white text-[12px] flex justify-center align-middle text-center bg-[red] h-[25px] w-[25px]">
                              <p className="self-center">
                                {notificationCounts > 99 ? '99+' : notificationCounts}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <Menu as="div">
                        <div className="flex">
                          <Menu.Button className="w-full justify-center text-sm font-medium text-gray-700 focus:outline-none">
                            <div className="flex relative">
                              {/* {userData?.profile ? (
                              // {false ? (
                                <LazyLoadImageProp
                                  src={userData.profile}
                                  className="w-[50px] h-[50px] self-center rounded-full border border-[#EAEAEA]"
                                />
                              ) : ( */}
                              <div className="self-center bg-black flex justify-center items-center w-9 aspect-square p-1 rounded-full">
                                <span className="text-white">{name && name[0]?.charAt(0)}</span>
                                <span className="text-white">{name && name[1]?.charAt(0)}</span>
                              </div>
                              {/* )} */}
                              <div className="text-start self-center ml-3 hidden lg:block">
                                <p className="text-white dark:text-white text-[18px] m-0">
                                  {userData?.name}
                                </p>
                                <span className="text-white dark:text-white font-light">
                                  {userData?.userType === 0
                                    ? 'Super Admin'
                                    : userData?.userType === 1
                                    ? 'Sub Admin'
                                    : userData?.userType === 3
                                    ? 'Company Admin'
                                    : userData?.userType === 4
                                    ? 'Company Sub Admin'
                                    : userData?.userType === 5
                                    ? 'Partner'
                                    : ''}
                                </span>
                              </div>
                              <ChevronDownIcon className="lg:ml-3 w-[25px] text-white" />
                            </div>
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute shadow-lg right-0 z-10 mt-2 w-56 origin-top-right rounded-md dark:bg-shoorah-darkBgTabColor bg-white ring-1 ring-shoorah-primary ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    to={
                                      userType === 3 || userType === 4
                                        ? `/${userData.slug}/change-password`
                                        : '/change-password'
                                    }
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 dark:text-white text-gray-900'
                                        : ' dark:text-white text-gray-700',
                                      'block px-4 py-2 text-sm hover:bg-inherit'
                                    )}
                                  >
                                    Change Password
                                  </Link>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                )}
              </motion.div>
            </AnimatePresence>

            {(userType === 3 || userType === 4) && (
              <motion.div
                initial={{
                  x: 100,
                  opacity: 0
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.5
                  }
                }}
                className="col-span-1 lg:block absolute lg:relative right-4 top-5 lg:right-auto lg:top-auto  lg:h-[95vh]"
              >
                <RightSidebar />
              </motion.div>
            )}

            {userType === 5 && (
              <motion.div
                initial={{
                  x: 100,
                  opacity: 0
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.5
                  }
                }}
                className="col-span-1 lg:block absolute lg:relative right-4 top-5 lg:right-auto lg:top-auto  lg:h-[95vh]"
              >
                <PartnerSidebar />
              </motion.div>
            )}

            {userType === 6 && (
              <motion.div
                initial={{
                  x: 100,
                  opacity: 0
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.5
                  }
                }}
                className="col-span-1 lg:block absolute lg:relative right-4 top-5 lg:right-auto lg:top-auto  lg:h-[95vh]"
              >
                <ExpertSidebar />
              </motion.div>
            )}
          </div>
        </>
      ) : (
        <>
          <LoadSideBar />
          <Suspense fallback={<Loader />}>
            <Routes>
              {RoutesFile.map((itm, key) =>
                itm.private ? (
                  <Route
                    key={key}
                    exact={itm.exact}
                    path={itm.path}
                    name={itm.name}
                    Component={itm.component}
                  />
                ) : (
                  <Route
                    key={key}
                    exact={itm.exact}
                    path={itm.path}
                    name={itm.name}
                    Component={itm.component}
                  />
                )
              )}
              <Route exact path="/login" element={<Login />}></Route>
              <Route exact path="/" element={<Navigate to="/login" />}></Route>
            </Routes>
          </Suspense>{' '}
        </>
      )}
    </>
  );
};

RoutesList.propTypes = {
  isShow: PropTypes.any,
  setShow: PropTypes.any
};

export default RoutesList;
